import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { Image } from "semantic-ui-react";
import { SubtitleInline } from "components/shared/Typography";
import { displayCoveragesProperties, formatContent, displayCoverageLimits, getCoverageFullName } from "utils/projectCoverageRequirementsUtils";

export default function DisplayProjectCoverages({ project, certificateHolder, logo, initialCoverageRequirements, projectLabel="project", ...props }) {
  const coverages = JSON.parse(initialCoverageRequirements?.coverages || project?.requiredCoverages || "[]") || [];

  if (!coverages.length) {
    return (
      <Typography color="red" variant="h6" my={2}>
        {`NOTICE: Selected ${projectLabel} does not contain coverage requirements`}
      </Typography>
    );
  }

  return (
    <Box component="div" {...props} width="100%">
      <SubtitleInline sx={{ my: 3 }}>
        {initialCoverageRequirements && "INITIAL "}COVERAGE REQUIREMENTS: {project?.name?.toUpperCase()}
      </SubtitleInline>
      {certificateHolder && (
        <SubtitleInline sx={{ color: "text.secondary", display: "flex", alignItems: "center" }}>
          Certificate Holder: <b style={{ backgroundColor: "#f3f4f7", marginRight: "1em" }}>{certificateHolder}</b>
          {logo && <Image size="mini" src={logo} />}
        </SubtitleInline>
      )}
      <Grid sx={{ my: 3 }} container spacing={0}>
        {coverages.slice(0, 5).map((coverage, index) => (
          <Grid item xs key={index} justifyContent="stretch" minWidth="20%">
            <Box border={0.4} borderColor="grey.500" height={"100%"}>
              <SubtitleInline
                sx={{
                  backgroundColor: "#f3f4f7",
                  px: "10px",
                }}
              >
                {getCoverageFullName(coverage?.insuranceType, coverage?.name)}
              </SubtitleInline>
              <ul style={{ padding: "10px", margin: "auto 10px" }}>
                {Object.entries(displayCoveragesProperties(coverage) || {}).map(([key, value]) => {
                  return (
                    <li key={`${coverage?.insuranceType}-${key}}`}>
                      <Typography fontSize={12} color="text.secondary">
                        {key}: <b>{formatContent(value)}</b>
                      </Typography>
                    </li>
                  );
                })}
                {displayCoverageLimits(coverage)}
              </ul>
            </Box>
          </Grid>
        ))}
        {coverages.slice(5, coverages.length).map((coverage, index) => (
          <Grid item xs key={index} justifyContent="stretch" minWidth="20%">
            <Box border={0.4} borderColor="grey.500" height={"100%"}>
              <SubtitleInline
                sx={{
                  backgroundColor: "#f3f4f7",
                  px: "10px",
                }}
              >
              {getCoverageFullName(coverage?.insuranceType, coverage?.name)}
              </SubtitleInline>
              <ul style={{ padding: "10px", margin: "auto 10px" }}>
                {Object.entries(displayCoveragesProperties(coverage) || {}).map(([key, value]) => {
                  return (
                    <li key={`${coverage?.insuranceType}-${key}}`}>
                      <Typography fontSize={12} color="text.secondary">
                        {key}: <b>{formatContent(value)}</b>
                      </Typography>
                    </li>
                  );
                })}
                {coverage?.limits && (
                  <li style={{ padding: "10px 0" }}>
                    Limits:
                    <ul style={{ paddingLeft: "10px" }}>
                      {Object.entries(coverage?.limits || {}).map(([key, value]) => {
                        return (
                          <li key={`limit-${coverage?.insuranceType}-${key}}`}>
                            <Typography fontSize={12} color="text.secondary">
                              {key}: <b>${formatContent(value)}</b>
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                )}
              </ul>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
