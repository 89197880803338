import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import {
  Radio,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Stack,
  Divider,
} from "@mui/material";

import {Accordion} from "semantic-ui-react";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css'

import LoaderButton from "components/shared/LoaderButton";
import EmailTemplate from "components/EmailTemplates/EmailTemplate";
import Modal from "components/shared/Modal";
import DisplayProjectCoverages from "components//AddVendorWizard/DisplayProjectCoverages";
import SubtitleInline from "components/shared/Typography/SubtitleInline";
import { emailProjectCoverageRequirementsTemplate } from "utils/projectCoverageRequirementsUtils";
import {getDefaultTemplates} from "constants/client_properties";
import certificate_actions from "constants/certificate_actions";
import { ROUTE_FRAGMENTS } from "constants/routes";
import {useDispatch} from "react-redux";
import { vendorActions } from "redux/vendorSlice";
import useVendorActivities from "hooks/useVendorActivities";
import AmplitudeService, { AMPLITUDE_EVENT } from "services/amplitude";

export default function EmailConfirmationModal({
  open,
  onClose,
  clientName,
  clientId,
  clientLogo,
  vendorName,
  clientVendorID,
  vendorEmail = '',
  projectLabel,
  templates = ['Custom'],
  clientTemplates,
  onEmailSentResult,
  getUploadLink,
  certificateId,
  vendorProjects = [],
  useClientDefaultProject,
  defaultProject,
  loggedInUser,
  additionalRecipients= []
}) {
  const dispatch = useDispatch();
  const [vendorActivities, addVendorActivity] = useVendorActivities()
  const [includeVendorEmail, setIncludeVendorEmail] = useState(true)
  const [selectedEmailAddresses, setSelectedEmailAddresses] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [emailTitle, setEmailTitle] = useState()
  const [emailSubject, setEmailSubject] = useState("");
  const [emailVendorProjects, setEmailVendorProjects] = useState();
  const [emailContent, setEmailContent] = useState("");
  const [emailSignature, setEmailSignature] = useState("");
  const [sending, setSending] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const defaultTemplates = getDefaultTemplates({ vendorName, clientName,projectLabel: projectLabel, clientProjects: vendorProjects.filter(p => !p.isDefault).map(project => project.name) })
  const projectRef = useRef();

  useEffect(() => {
    setSelectedEmailAddresses([...additionalRecipients])
  },[additionalRecipients]);

  useEffect(() => {
    let templatesJson = clientTemplates
    if(!clientTemplates) {
      templatesJson = defaultTemplates
    } else if(typeof clientTemplates === 'string') {
      templatesJson = JSON.parse(clientTemplates)
    }
    templatesJson = {
      ...defaultTemplates,
     ...( templates?.[0] !== certificate_actions.SHARE_CERTIFICATE && {...templatesJson}),
    }
    setEmailSubject(templatesJson?.[selectedTemplate]?.subject || "")
    setEmailVendorProjects(defaultTemplates?.[selectedTemplate]?.projectList)
    setEmailContent(templatesJson?.[selectedTemplate]?.content || "")
    setEmailSignature(templatesJson?.[selectedTemplate]?.signature || "")
    setIncludeVendorEmail(true)
  }, [clientTemplates, selectedTemplate])

  useEffect(() => {
    setSelectedTemplate(templates?.[0])
    setEmailTitle(templates?.[0] === certificate_actions.SHARE_CERTIFICATE ?`Sharing certificate with ${vendorName}` :`Send Email to ${vendorName}`)
  }, [templates])

  useEffect(() => {
    if (useClientDefaultProject) {
      setSelectedProject(defaultProject);
    } else {
      setSelectedProject(vendorProjects?.find(project => project?.isDefault) || vendorProjects[0])
    }
  }, [vendorProjects])

  function handleTemplateChange(event) {
    setSelectedTemplate(event.target.value)
  }

  function onFieldUpdate(event) {
    const value = event.target.value
    if(event.target.name === 'subject') {
      setEmailSubject(value)
    } else if (event.target.name === 'signature') {
      setEmailSignature(value)
    } else {
      setEmailContent(value)
    }
  }

  function handleEmailAddressesChange(emails) {
    setSelectedEmailAddresses(emails)
  }

  function handleProjectChange(event) {
    setSelectedProject(vendorProjects?.find(project => project?.id === event.target.value))
  }

  function handleEmailAddressesRemove(index) {
    setSelectedEmailAddresses([
      ...selectedEmailAddresses.slice(0, index),
      ...selectedEmailAddresses.slice(index + 1)
    ])
  }

  async function onSendEmail() {
    const recipients = [...selectedEmailAddresses]
    if(includeVendorEmail) {
      recipients.push(vendorEmail)
    }
    setSending(true)

    let uploadLink;
    if(selectedTemplate === certificate_actions.REQUEST && getUploadLink) {
      uploadLink = await getUploadLink(certificateId, projectRef?.current?.value || undefined, null, [...recipients])
    } else if (selectedTemplate === certificate_actions.SHARE_CERTIFICATE && getUploadLink) { 
      uploadLink = await getUploadLink(certificateId, projectRef?.current?.value || undefined, ROUTE_FRAGMENTS.SHARED)
    }

    const emailContentToSend = emailVendorProjects ? emailVendorProjects + emailContent : emailContent;

    const result = await dispatch(vendorActions.sendEmailToVendor({
      subject: emailSubject,
      content: emailContentToSend,
      signature: emailSignature,
      vendorName,
      clientName,
      recipients,
      action: selectedTemplate,
      ...(uploadLink ? {uploadLink} : {})
    }))
    setSending(false)
    if (result?.payload?.success) {
      let emailMessage;
      if (selectedTemplate === certificate_actions.REQUEST && getUploadLink) {
        emailMessage = "requested certificate from ";
        uploadLink = uploadLink + "/SHARED"
        AmplitudeService.logEvent(AMPLITUDE_EVENT.CERTIFICATE_REQUESTED.eventName, 
          AMPLITUDE_EVENT.CERTIFICATE_REQUESTED.eventProperties(
            {
              userEmail: loggedInUser?.email,
              clientId: clientId,
              clientName: clientName
            })
        )
      } else if (selectedTemplate === certificate_actions.SHARE_CERTIFICATE) {
        emailMessage = "shared certificate with ";
      } else {
        emailMessage = "sent email to ";
      } 
        addVendorActivity({
          action: "EMAIL_SEND",
          clientVendorID: clientVendorID,
          vendor: {
            message: emailMessage + recipients.join(','),
            details: {
              emailSubject,
              emailContent,
              ...(uploadLink && { certificateLink: uploadLink }),
              emailSignature
            }
          }
        })
    }
    setEmailSubject("")
    setEmailContent("")
    setEmailSignature("")
    setIncludeVendorEmail(true)
    setSelectedEmailAddresses([])
    onClose && onClose()
    onEmailSentResult && onEmailSentResult(result?.payload?.success)
  }

  const templatePanels = templates.map((templateName, index) => {
    return (
      {
        key: templateName,
        title: {
          icon: (
            <FormControlLabel
              value="request"
              control={
                <Radio
                  value={templateName}
                  checked={templateName === selectedTemplate}
                  onChange={handleTemplateChange}
                />
              }
              label={templateName}
            />
          ),
        },
        content: {
          content: (
            <div>
              <EmailTemplate
                template={{subject: emailSubject, content: emailContent, signature: emailSignature, projectList: emailVendorProjects}}
                isEdit={true}
                onFieldUpdate={onFieldUpdate}
                vendorName={vendorName}
              />
            </div>
          )
        },
      }
    )
  })

  return (
    <Modal
      maxWidth={"lg"}
      title={emailTitle}
      open={open}
      content={
        <>
          <SubtitleInline>
            Recipient(s)
          </SubtitleInline>
          <Stack sx={{mb: 2}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeVendorEmail}
                  onChange={(event) => setIncludeVendorEmail(event.target.checked)}
                />
              }
              label={vendorEmail}
            />
            <ReactMultiEmail
              placeholder="Other recipients"
              emails={selectedEmailAddresses}
              onChange={(_emails) => handleEmailAddressesChange(_emails)}
              validateEmail={email => isEmail(email)}
              getLabel={
                (email, index) => (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => handleEmailAddressesRemove(index)}>
                      ×
                    </span>
                  </div>
                )
            }
            />
          </Stack>
          {
            selectedTemplate === certificate_actions.REQUEST && certificateId === "create" && vendorProjects?.length &&
            <>
              <Divider />
              <SubtitleInline sx={{ mt: 2 }}>Select A { projectLabel}</SubtitleInline>
              <Select
                size="small"
                sx={{ minWidth: 120, mb: 2}}
                value={selectedProject?.id || ""}
                displayEmpty={true}
                inputProps={{ ref: projectRef }}
                onChange={handleProjectChange}
                fullWidth
                disabled= {useClientDefaultProject}
              >
                {
                  useClientDefaultProject ?
                  <MenuItem key={"invite-project-" + defaultProject.name} value={defaultProject.id}>{defaultProject.name}</MenuItem>
                :
                  vendorProjects?.map(vendorProject => (
                    <MenuItem key={"invite-project-" + vendorProject.name} value={vendorProject.id}>{vendorProject.name}</MenuItem>
                  ))
                }
              </Select>
              <DisplayProjectCoverages projectLabel={projectLabel} project={selectedProject} certificateHolder={clientName} logo={clientLogo} />
            </>
          }
          <Divider />
          <SubtitleInline sx={{mt: 2}}>
            { templates.length > 1
              ? "Select Email Template"
              : "Email Preview"
            }
          </SubtitleInline>
          {
            templates.length > 1 &&
            <Accordion
              activeIndex={ selectedTemplate ? templates.indexOf(selectedTemplate) : -1}
              styled
              fluid
              panels={templatePanels}
            />
          }
          {
            templates.length <= 1 &&
            <EmailTemplate
              template={{subject: emailSubject, content: emailContent, signature: emailSignature, projectList: emailVendorProjects}}
              isEdit={true}
              onFieldUpdate={onFieldUpdate}
              vendorName={vendorName}
              vendorProjects={vendorProjects.map(project => project.name)}
            />
          }
        </>
      }
      actions={
        <>
          <Button variant="outlined" color="success" onClick={onClose}>Cancel</Button>
          <LoaderButton
            loading={sending}
            variant="contained"
            color="success"
            onClick={onSendEmail}
            disabled={selectedEmailAddresses.length === 0 && !includeVendorEmail}
          >Send</LoaderButton>
        </>
      }
    />
  )
}
