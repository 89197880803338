export const getClientCategories = /* GraphQL */ `
    query GetClientCategories($id: ID!) {
        getCLIENT(id: $id) {
            mode
            clientCategories {
                items {
                    name
                    id
                    createdAt
                    updatedAt
                    requiredCoverages
                    vendors {
                        items {
                            id
                            primary
                            clientVendor {
                                vendor {
                                    id
                                    name
                                    city
                                    state
                                    email
                                    phone
                                    createdAt
                                }
                                status
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const updateClientCategory = /* GraphQL */ `
    mutation UpdateCATEGORY(
        $id: ID! 
        $name: String!
    ) 
    {
        updateCATEGORY(input: {
            id: $id,
            name: $name
        }) {
            id
            name
        }
    }
`

export const updateCategoryCoverage = /* GraphQL */ `
    mutation UpdateCATEGORY(
        $id: ID!
        $requiredCoverages: AWSJSON
    )
    {
        updateCATEGORY(input: {
            id: $id,
            requiredCoverages: $requiredCoverages
        }) {
            id
            requiredCoverages
        }
    }
`

export const createClientCategory = /* GraphQL */ `
    mutation CreateCATEGORY(
        $name: String!
        $clientID: ID!
    )
    {
        createCATEGORY(input: {
            name: $name,
            clientID: $clientID
        }) {
            id
            name
            createdAt
            vendors {
                items {
                    id
                    primary
                }
            }
        }
    }
`

export const deleteClientCategory = /* GraphQL */ `
    mutation DeleteCATEGORY(
        $id: ID!
    )
    {
        deleteCATEGORY(input: {
            id: $id
        }) {
            id
            name
            clientID
            requiredCoverages
        }
    }
`

export const getClientProjects = /* GraphQL */ `
    query GetClientProjects($id: ID!) {
        getCLIENT(id: $id) {
            mode
            clientProjects {
                items {
                    id
                    name
                    createdAt
                    description
                    startDate
                    endDate
                    status
                    address
                    state
                    zip
                    requiredCoverages
                    isDefault
                    vendors {
                        items {
                            id
                            clientVendor {
                                id
                                vendor {
                                    id
                                    name
                                    city
                                    state
                                    email
                                    phone
                                    createdAt
                                }
                                status
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getProject = /* GraphQL */ `
    query GetClientProjects($id: ID!) {
        getPROJECT(id: $id) {
            id
            name
            createdAt
            description
            startDate
            endDate
            status
            requiredCoverages
            clientID
            isDefault
            address
            city
            state
            zip
            vendors {
                items {
                    id
                    clientVendor {
                        id
                        vendor {
                            id
                            name
                            city
                            state
                            email
                            phone
                            createdAt
                        }
                        status
                    }
                }
            }
        }
    }
`;

export const createClientProject = /* GraphQL */ `
    mutation CreatePROJECT(
        $name: String!
        $clientID: ID!
        $description: String
        $startDate: AWSDate
        $endDate: AWSDate
        $isDefault: Boolean
    )
    {
        createPROJECT(
            input: {
                name: $name,
                clientID: $clientID,
                description: $description,
                startDate: $startDate,
                endDate: $endDate,
                status: ACTIVE,
                isDefault: $isDefault
            }
        ) {
            id
            name
            createdAt
            description
            startDate
            endDate
            status
            isDefault
            address
            city
            state
            zip
            createdAt
            vendors {
                items {
                    id
                }
            }
        }
    }
`

export const updateClientProject = /* GraphQL */ `
    mutation UpdatePROJECT(
        $input: UpdatePROJECTInput!
    )
    {
        updatePROJECT(input: $input) {
            id
            name
            createdAt
            description
            startDate
            endDate
            status
            address
            city
            state
            zip
            createdAt
            requiredCoverages
            vendors {
                items {
                    id
                    clientVendor {
                        vendor {
                            id
                            name
                            city
                            state
                            email
                            phone
                            createdAt
                        }
                        status
                    }
                }
            }
        }
    }
`

export const makeProjectActive = /* GraphQL */ `
    mutation UpdatePROJECT($id: ID!)
    {
        updatePROJECT(
            input: {
                id: $id,
                status: ACTIVE
            }
        ) {
            id
            status
        }
    }
`

export const makeProjectInactive = /* GraphQL */ `
    mutation UpdatePROJECT($id: ID!)
    {
        updatePROJECT(
            input: {
                id: $id,
                status: INACTIVE
            }
        ) {
            id
            status
        }
    }
`

export const makeProjectArchived = /* GraphQL */ `
    mutation UpdatePROJECT($id: ID!)
    {
        updatePROJECT(
            input: {
                id: $id,
                status: ARCHIVED
            }
        ) {
            id
            status
        }
    }
`

export const updateCLIENTCOVERAGE = /* GraphQL */ `
    mutation UpdateCLIENTCOVERAGE(
        $input: UpdateCLIENTCOVERAGEInput!
    ) {
        updateCLIENTCOVERAGE(input: $input) {
            id
            name
            insuranceType
            insuranceForm
            aggregateAppliesPer
            coveredProperty
            additionalInsured
            subrogationWaiver
            deductible
            retention
            exclusion
            limits
            limitPerStatute
            createdAt
            updatedAt
        }
    }
`;

export const createCLIENTCOVERAGE = /* GraphQL */ `
    mutation CreateCLIENTCOVERAGE(
        $input: CreateCLIENTCOVERAGEInput!
    ) {
        createCLIENTCOVERAGE(input: $input) {
            id
            name
            insuranceType
            insuranceForm
            aggregateAppliesPer
            coveredProperty
            additionalInsured
            subrogationWaiver
            deductible
            retention
            exclusion
            limits
            limitPerStatute
            createdAt
            updatedAt
        }
    }
`;

export const certificateByClient = /* GraphQL */ `
    query CertificateByClient(
        $clientID: ID!
        $filter: ModelCERTIFICATEFilterInput
        $limit: Int
        $nextToken: String
    ) {
        certificateByClient(
            clientID: $clientID
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                clientVendorID
                certificateDate
                firstExp
                producer
                insured
                status
                sentBy
                sentTo
                readBy
                coverages {
                    items {
                        id
                        name
                        insuranceType
                        policyNumber
                    }
                }
                projectID
                updatedAt
                createdAt
            }
            nextToken
            scannedCount
        }
    }
`;


// Custom GraphQL resolver powered by "getActiveCertificates" lambda function 

export const getActiveCertificates = /* GraphQL */ `
  query GetActiveCertificates(
    $clientID: ID!
    $indexName: ACTIVECERTIFICATESNDEXNAME
    $status: String
    $statusFilter: String
    $nextToken: String
    $limit: Int
    $sortDirection: String
  ) {
    getActiveCertificates(
      clientID: $clientID
      indexName: $indexName
      status: $status
      statusFilter: $statusFilter
      nextToken: $nextToken
      limit: $limit
      sortDirection: $sortDirection
      ) {
        items {
            id
            name
            clientVendorID
            clientID
            projectID
            certificateDate
            producer
            insured
            status
            updatedAt
            firstExp
            createdAt
            sentBy
            sentTo
            readBy
      }
      nextToken
      scannedCount
    }
  }
`;

export const certificateByProject = /* GraphQL */ `
    query CertificateByProject(
        $projectID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelCERTIFICATEFilterInput
        $limit: Int
        $nextToken: String
    ) {
        certificateByProject(
            projectID: $projectID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                certificateDate
                producer
                firstExp
                insured
                status
                coverages {
                    items {
                        id
                        name
                        insuranceType
                        policyNumber
                    }
                }
                projectID
                createdAt
                updatedAt
                sentBy
                sentTo
                readBy
            }
        }
    }
`;

export const updateCLIENTTemplates = /* GraphQL */ `
    mutation UpdateCLIENT(
        $input: UpdateCLIENTInput!
    ) {
        updateCLIENT(input: $input) {
            templates
        }
    }
`;

export const certificateByClientQueries = {
    getActiveCertificates
}
